<template>
  <b-card
    title="Getnet"
    class="top-level p-1"
  >
    <b-row align-v="center">
      <b-col md="4">
        <b-form-group>
          <label>Seller ID Getnet</label>
          <b-form-input
            id="GETNET_SELLER_ID"
            v-model="GETNET_SELLER_ID.value"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-button
          class="float-right"
          variant="success"
          @click.stop="saveKey(GETNET_SELLER_ID)"
        >
          Gravar
        </b-button>
      </b-col>
    </b-row>
    <b-row align-v="center">
      <b-col md="4">
        <b-form-group>
          <label>Client ID Getnet</label>
          <b-form-input
            id="GETNET_CLIENT_ID"
            v-model="GETNET_CLIENT_ID.value"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-button
          class="float-right"
          variant="success"
          @click.stop="saveKey(GETNET_CLIENT_ID)"
        >
          Gravar
        </b-button>
      </b-col>
    </b-row>

    <b-row align-v="center">
      <b-col md="4">
        <b-form-group>
          <label>Client Secret Getnet</label>
          <b-form-input
            id="GETNET_CLIENT_SECRET"
            v-model="GETNET_CLIENT_SECRET.value"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-button
          class="float-right"
          variant="success"
          @click.stop="saveKey(GETNET_CLIENT_SECRET)"
        >
          Gravar
        </b-button>
      </b-col>
    </b-row>

    <b-row align-v="center">
      <b-col md="4">
        <b-form-group>
          <label>Número limite de parcelas Getnet</label>
          <b-form-input
            id="GETNET_INSTALLMENTS_LIMIT"
            v-model="GETNET_INSTALLMENTS_LIMIT.value"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-button
          class="float-right"
          variant="success"
          @click.stop="saveKey('GETNET_INSTALLMENTS_LIMIT')"
        >
          Gravar
        </b-button>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBPopover, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BForm,
  BFormCheckbox, BFormDatepicker,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { forEach } from 'postcss-rtl/lib/affected-props'

export default {
  components: {
    vSelect,
    BFormDatepicker,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    attributeId: {
      type: String,
      required: false,
      default: '-1',
    },
  },
  data() {
    return {
      keys: [
        'GETNET_SELLER_ID',
        'GETNET_CLIENT_ID',
        'GETNET_CLIENT_SECRET',
        'GETNET_INSTALLMENTS_LIMIT',
      ],
      GETNET_SELLER_ID: {
        value: '',
      },
      GETNET_CLIENT_ID: {
        value: '',
      },
      GETNET_CLIENT_SECRET: {
        value: '',
      },
      GETNET_INSTALLMENTS_LIMIT: {
        value: '',
      },
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.keys.forEach(key => {
      this.$store.dispatch('configurations/fetchConfiguration', key).then(result => {
        this[key] = result
      })
    })
  },
  methods: {
    saveKey(key) {
      this.$store.dispatch('configurations/updateConfiguration', this[key]).then(result => {
        this[key] = this.$store.state.configurations.configuration

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Getnet',
            variant: 'success',
            icon: 'CheckIcon',
            text: 'Gravado com sucesso.',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Getnet',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Não conseguimos gravar. Verificar que todos os dados estão visíveis sem ***.',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.top-level {
  background: lightgrey;
}
</style>
